import React, { useEffect, useRef } from 'react'

export const PublicidadGrande = () => {
    
    const banner=useRef();
    const atOptions={
        'key' : 'd38377b95205c8cdaf1a6dead4797e69',
		'format' : 'iframe', 
		'height' : 90,
		'width' : 728,
		'params' : {}
    }
    useEffect(()=>{
        if(!banner.current.firstChild){
            const conf=document.createElement('script');
            const script=document.createElement('script');
            script.type="text/javascript";
            script.src="//www.highcpmcreativeformat.com/d38377b95205c8cdaf1a6dead4797e69/invoke.js";
            conf.innerHTML=`atOptions=${JSON.stringify(atOptions)}`
            if(banner.current){
                banner.current.append(conf);
                banner.current.append(script)
            }
        }
    });

  return (
    <article >
        <div ref={banner}></div>
    </article>
  )
}
