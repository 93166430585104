import React, { useEffect, useState } from 'react'
import { PublicidadMediana } from './PublicidadMediana';
import { PublicidadGrande } from './PublicidadGrande';
import { PublicidadPequeña } from './PublicidadPequeña';

import '../../Estilos/Publicidad.css'
export const Anuncios = () => {
  const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);

  useEffect(() => {
    const manejarCambioDeTamaño = () => {
      setAnchoPantalla(window.innerWidth);
    };

    window.addEventListener('resize', manejarCambioDeTamaño);

    return () => {
      window.removeEventListener('resize', manejarCambioDeTamaño);
    };
  }, []);

  return (
    <div>
      {anchoPantalla > 800 ? (
        <PublicidadGrande />
      ) : anchoPantalla>600 ?(
        <PublicidadMediana />
      ):(
        <PublicidadPequeña/>
      )}
    </div>
  );

}
