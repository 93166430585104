import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { Footer } from "../componentes/Layout/Footer";
import { Header } from "../componentes/Layout/Header";
import { CryptoPage } from "../componentes/Pages/CryptoPage";
import { Movil } from "../componentes/Pages/Movil";
import { PublicidadCostado } from "../componentes/Publicidad/PublicidadCostado";
import { Blog } from "../componentes/Pages/Blog";
// import { Home } from "../componentes/Pages/Home";
import { Test } from "../componentes/Pages/Test";
import { Anuncios } from "../componentes/Publicidad/Anuncios";
export const Rutas = () => {
  return (
    <HashRouter>
      <Header />
      <section className="content">
        <section className="publicidad__arriba">
          <Anuncios/>
        </section>
        <section className="articulo-intro">
          <Test/>
        </section>
        {/*---------------------------------------------------------------------------------------------*/}
        <section className="container">
        <Routes>
          <Route path="/" element={<CryptoPage/>} />
          <Route path="/Blog/:Articulo" element={<Blog/>}/>
          {/* <Route path="/Home" element={<Home/>}/> */}
          <Route path="/Cryptomonedas" element={<CryptoPage/>} />
          <Route path="/Movil" element={<Movil />} />
        </Routes>
        </section>
        {/*---------------------------------------------------------------------------------------------*/}
        <section className="publicidad__costado">
          <PublicidadCostado contenido={<div id="container-c5c5ca5c956582a36d7fae327b65f5e7"></div>} clase={"pubd"}/>
        </section>
        {/*---------------------------------------------------------------------------------------------*/}
      </section>
        <Footer />
    </HashRouter>
  );
};
