import React from "react";
// import { PublicidadInter } from "./Publicidad/PublicidadInter";

export const Articulo = ({ titulo, enlace, texto }) => {
  return (
    <article className="articulo-contenedor">
      <div className="div-titulo">
        <a
          className="a-titulo"
          href={enlace}
          target={"_blank"}
          rel="noreferrer"
        >
          <h1>{titulo}</h1>
        </a>
      </div>
      <div className="div-pub">
        <p>{texto}</p>
      </div>
      {/* <PublicidadInter /> publicidad intermedia de los articulos*/}
    </article>
  );
};
