import React from 'react'
import { useLocation } from 'react-router-dom';
import { Url } from '../../Global/Url';
import { ArtIntroduccion } from '../ArtIntroduccion';

export const Test = () => {
    // Obtener la ubicación actual
  const location = useLocation();
  const url = location.pathname;
  return (
    <>
      {url === '/Cryptomonedas' || url==="/"? (
        <>
          {/* Contenido si la URL es '/Cryptomonedas' */}
          <ArtIntroduccion
          titulo="Descubre Cómo Ganar Criptomonedas desde Casa."
          texto={
            <>
              Las criptomonedas están cada vez más al alcance de las personas
              comunes y corrientes, como nosotros, sin necesidad de tener
              amplios conocimientos en minería o del mercado de las
              criptomonedas. Aunque es cierto que la minería puede generar
              mayores rendimientos a largo plazo, requiere una inversión inicial
              considerable y conocimientos especializados para armar nuestro
              propio rack de minería. Sin embargo, dado que el futuro de las
              divisas parece estar en las monedas virtuales, es conveniente
              adentrarnos en este mundo. Aquí encontrarás una lista actualizada
              de las mejores páginas para ganar criptomonedas, ya que muchas de
              las páginas que se encuentran en internet dejan de pagar o
              resultan ser estafas. Las páginas que te presentaré han sido
              probadas y se ha verificado que realizan los pagos. La mayoría de
              estas páginas deposita a través de Faucetpay, por lo que te
              recomiendo crear una cuenta haciendo clic en el siguiente enlace{" "}
              {Url.faucetpay}. Faucetpay nos permite almacenar nuestras
              criptomonedas y, si lo deseamos, podemos retirarlas a Bitso o
              Binance.
            </>
          }
        />
        </>
      ) : url === '/Movil' ? (
        <>
          {/* Contenido si la URL es /Movil */}
          <ArtIntroduccion
          titulo={"Descubre las Mejores Apps para Ganar Dinero"}
          texto={
            <>
              En la era digital, aprovechar las oportunidades que nos brinda internet y nuestros smartphones se ha vuelto una excelente manera de obtener ingresos adicionales. A través de diversas aplicaciones, podemos realizar actividades que nos permiten ganar unos cuantos pesos al mes, sin embargo, es importante tener cuidado y seleccionar las opciones adecuadas, ya que existen estafas y requisitos absurdos en el mercado. En esta lista, encontrarás aplicaciones que he probado personalmente y puedo asegurarte que son confiables, pagando a través de métodos como PayPal, tarjetas de regalo y otros medios de pago. Lo mejor de todo es que no tendrás que proporcionar información de tu tarjeta de crédito o débito. Para obtener mejores resultados, es clave mantener la constancia y la paciencia. Te recomiendo que, una vez te hayas adaptado a una aplicación, descargues más de la lista que te presento a continuación, para aumentar aún más tus ingresos adicionales. ¡Descubre cómo ganar dinero de forma segura y efectiva con estas aplicaciones móviles y aprovecha al máximo tu tiempo y tu dispositivo!"
            </>
          }
        />
        </>
      ) : (
        // Contenido predeterminado si la URL no coincide con ninguna condición anterior
        ''
      )}
    </>
  )
}
