import { useEffect } from 'react'

export const Cabecera = ({titulo,descripcion}) => {
  useEffect(()=>{
    document.title=titulo;
    const metaDescripcion=document.querySelector('meta[name="description"]');
    if(metaDescripcion){
      metaDescripcion.content=descripcion;
    }else{
      const nuevoMeta=document.createElement('meta');
      nuevoMeta.name='description';
      nuevoMeta.content=descripcion;
      document.head.appendChild(nuevoMeta);
    }
    //limpieza
    return()=>{
      document.title='';
      const metaDescripcion=document.querySelector('meta[name="description"]');
      if(metaDescripcion){
        document.head.removeChild(metaDescripcion);
      }
    };
  },[titulo,descripcion]);
  return null;
}
