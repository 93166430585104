export const Url={
    faucetpay:<strong><a href="https://faucetpay.io/?r=3707980" target={"_blank"} rel="noreferrer">FaucetPay</a></strong>,
    adbtc:<strong><a href='https://r.adbtc.top/2862993' target={"_blank"} rel="noreferrer">adBTC</a></strong> ,
    payeer:<strong><a href='https://payeer.com/028190052' target={"_blank"} rel="noreferrer">Payeer</a></strong> ,
    pollpay:<strong><a href="https://pollpay.onelink.me/hjPo/GetPollPay" target={"_blank"} rel="noreferrer">PollPay</a></strong> ,
    appflame:<strong><a href="https://affiliate.justtrack.io/android/online.appflame.app/213e0b87-9607-454b-a976-13be44fd3d70?channel=intent" target={"_blank"} rel="noreferrer">App Flame</a></strong> ,
    premise:<strong><a href="https://premise.app.link/fkPAD72VKwb" target={"_blank"} rel="noreferrer">Premise</a></strong> ,
    google:<strong><a href="https://googleopinionrewards.page.link/share" target={"_blank"} rel="noreferrer">Google Opinion Rewards</a></strong> ,
    attapoll:<strong><a href="https://attapoll.app/join/pvdhm" target={"_blank"} rel="noreferrer">AttaPoll</a></strong>,
    keddyearner:<strong><a href="https://kiddyearner.com/?r=58896" target={"_blank"} rel="noreferrer">kiddyearner</a></strong>,
    aviso:<strong><a href="https://aviso.bz/?r=juanvera" target={"_blank"} rel="noreferrer">Aviso.</a></strong>,
    yescoiner:<strong><a href="https://yescoiner.com/faucet?ref=2132865" target={"_blank"} rel="noreferrer">Yescoiner</a></strong>,
    claimFreeBTC:<strong><a href="https://claimfreecoins.io/bitcoin-faucet/?r=1LgxHQoMHoH2KdmfqC15UKwKFaFrfP8cKK" target={"_blank"} rel="noreferrer">Claim Free Coins</a></strong>,
    letyshops:<strong><a href="https://letyshops.com/mx/winwin?ww=27240054" target={"_blank"} rel="noreferrer">LetyShops</a></strong>,

    
}