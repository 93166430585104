import React, { useEffect, useRef } from 'react'

export const PublicidadMediana=()=>{
    const banner=useRef();
    const atOptions={
        'key' : 'facdcde20acdaa7a9c7519f99068e277',
		'format' : 'iframe', 
		'height' : 60,
		'width' : 468,
		'params' : {}
    }
    useEffect(()=>{
        if(!banner.current.firstChild){
            const conf=document.createElement('script');
            const script=document.createElement('script');
            script.type="text/javascript";
            script.src="//www.highcpmcreativeformat.com/facdcde20acdaa7a9c7519f99068e277/invoke.js";
            conf.innerHTML=`atOptions=${JSON.stringify(atOptions)}`
            if(banner.current){
                banner.current.append(conf);
                banner.current.append(script)
            }
        }
    });
  return (
    <article >
        <div ref={banner}></div>
    </article>
  )
}
