import React from 'react'
import { NavLink } from 'react-router-dom'

export const Menu = ({estado}) => {
  return (
    <nav className={`menu-contenedor-nav ${estado?'Activo':''}`}>
        <ul>
          {/* <li> <NavLink to={"/Home"}>Home</NavLink></li> */}
            <li>
                <NavLink to={"/Cryptomonedas"}>Cryptomonedas</NavLink>
            </li>
            <li>
                <NavLink to={"/Movil"}>Apps moviles</NavLink>
            </li>
        </ul>
    </nav>
  )
}
