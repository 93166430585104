
import React from 'react'
import {useLocation} from 'react-router-dom';
import { Cabecera } from '../Layout/Cabecera';
import { ArtsBlogP } from '../../Articulos/ArtsBlogsP';
import { ArticuloBlog } from '../Generales/ArticuloBlog';
export const Blog = () => {
  //---------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const router=useLocation();
  const path=router.pathname;
  console.log(path);
  // Obtén el nombre del artículo de la URL
  const nombreArticulo = path.substring(path.lastIndexOf('/') + 1);
  console.log(nombreArticulo);
  // Busca el elemento en ArtsBlog que tenga el mismo nombre de artículo
  const articulo = ArtsBlogP.filter((item) => item.nombre === nombreArticulo);
  const {titulo,descripcion,imagen,infoimg,cuerpo}=articulo[0];
  //---------------------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
    <Cabecera titulo={titulo} descripcion={"Pruebas"}/>
    <main>
        <ArticuloBlog titulo={titulo} cuerpo={cuerpo} imagen={imagen} infoimg={infoimg}/>
    </main>
    </>
  )
}