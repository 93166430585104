import React, { useEffect, useRef } from 'react'

export const PublicidadPequeña = () => {
    const banner=useRef();
    const atOptions={
        'key' : '71087928b7b27ef0656811d24d126d9a',
		'format' : 'iframe', 
		'height' : 50,
		'width' : 500,
		'params' : {}
    }
    useEffect(()=>{
        if(!banner.current.firstChild){
            const conf=document.createElement('script');
            const script=document.createElement('script');
            script.type="text/javascript";
            script.src="//www.highcpmcreativeformat.com/71087928b7b27ef0656811d24d126d9a/invoke.js";
            conf.innerHTML=`atOptions=${JSON.stringify(atOptions)}`
            if(banner.current){
                banner.current.append(conf);
                banner.current.append(script)
            }
        }
    });
  return (
    <article>
        <div ref={banner} className='pubPequeña'></div>
    </article>
  )
}
