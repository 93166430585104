import React from 'react'
import '../../Estilos/ArticuloBlog.css'
export const ArticuloBlog = ({titulo,cuerpo,imagen,infoimg}) => {
  return (
    <article>
        {/*Titulo del blog */}
        <div className='tituloBlog-div'>
            <h1 className='blog-h1'>{titulo}</h1>
            <hr className='linea-sombreada'/>
            <div className='blog-autor-div'>
                <p className='blog-p-autor'>Por Juan</p>
                <div className='time-blog'>
                     <time>10/06/2023</time>
                </div>
            </div>
            {/*Imagen*/}
            {imagen ? (
              <div className='imagenBlog-div'>
                <img className='imagen-blog' src={require(`../../imagenes/Pub/${imagen}`)} alt={infoimg}/>
              </div>
            ) :(<></>)}
        </div>
        {/*Contenido del blog*/}
        <div className='contenidoBlog-div'>
            {cuerpo}
        </div>
    </article>
  )
}
