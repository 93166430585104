import React from "react";

export const ArtIntroduccion = ({ titulo,texto}) => {
  return (
    <article className="articuloIntro-contenedor">
  
      <div className="div-titulo">
           <h1>{titulo}</h1>  
        </div>
      <div className="div-texto">
        <p>{texto}</p>
      </div>
    </article>
  );
};
