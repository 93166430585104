import React from "react";
import "../../Estilos/Footer.css";
import emailIcon from '../../imagenes/Recursos/email-icon.svg';
import dadoIcon from '../../imagenes/Recursos/dado-icon.svg'
import "../../Estilos/Footer.css";
export const Footer = () => {
  return (
    <footer className="footer">
      <div className="titulo">
        <h2>Dinero Fácil</h2>
      </div>
      <div className="lista">
      <div className="contacto">
        <div>
          <div className="h1-div">
            <figure className="figure__img">
              <img src={emailIcon} alt="Email Icon" className="img-icon"/>
            </figure>
            <h1>Contacto</h1>
          </div>
          <a className="a-footer" href="https://outlook.live.com/" target={"_blank"} rel="noreferrer">
            herz_rot_slomana@hotmail.com
          </a>
        </div>
      </div>

      <div className="visita-contenedor">
        <div>
        <div className="h1-div">
            <figure className="figure__img">
              <img src={dadoIcon} alt="Email Icon" className="img-icon"/>
            </figure>
          <h1>Visita</h1>
        </div>
          <a className="a-footer" href="https://virtualrandomgames.com/" target={"_blank"} rel="noreferrer">virtualrandomgames.com</a>
        </div>
      </div>
      </div>
    </footer>
  );
};
