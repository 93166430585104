import React, { useState } from "react";
import "../../Estilos/Header.css";
import { Menu } from "./Menu";
export const Header = () => {
  //--------------------------------------------------------------------------------------------------
  const [estado, setEstado] = useState(false);
  //--------------------------------------------------------------------------------------------------
  const captura = () => {
    const linea1 = document.querySelector(".line1-barra-menu");
    const linea2 = document.querySelector(".line2-barra-menu");
    const linea3 = document.querySelector(".line3-barra-menu");
    const menu = document.querySelector(".menu-contenedor-nav");
    linea1.classList.toggle("activeline1-barra-menu");
    linea2.classList.toggle("activeline2-barra-menu");
    linea3.classList.toggle("activeline3-barra-menu");
    menu.classList.toggle("active");
    setEstado(!estado); // valor contrario
  };
  //--------------------------------------------------------------------------------------------------
  return (
    <header>
      
      <div className="titulo-contenedor">
          <div className="logo-div">
            <a href="/" className="hedear-a">
              <p className="Nombre">Dinero Fácil</p>
            </a>
          </div>  
      </div>
      
      <div className="menu-contenedor">
        <div className="barra-menu" onClick={captura}>
          <span className="line1-barra-menu"></span>
          <span className="line2-barra-menu"></span>
          <span className="line3-barra-menu"></span>
        </div>
      </div>
      <Menu estado={estado} />
    </header>
  );
};
