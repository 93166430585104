
import { Rutas } from './router/Rutas';

export function App() {
  return (
    <>
      <Rutas/>
    </>
  );
}

